<template>
  <div class="createRoom">
    <div class="card">
      <div class="title">{{$t('社交聊天')}}</div>
      <van-field class="roomName"
                 v-model="room_name"
                 label-width="0"
                 input-align="center"
                 :class="{'vn-italic': $i18n.locale == 'vn'}"
                 :placeholder="$t('聊天室名称')" />
      <van-field class="number"
                 v-model="number"
                 readonly
                 label-width="0"
                 input-align="center"
                 :class="{'vn-italic': $i18n.locale == 'vn'}"
                 :placeholder="$t('人数')">
        <template #right-icon>
          <img @click="numShow = true"
               class="xia"
               src="@/assets/bracelet_img/44.png"
               alt="">
        </template>
      </van-field>
      <!-- <div class="btn creat">创建</div> -->
      <van-button class="btn creat"
                  :loading="creatLoading"
                  :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
                  @click="creatRoom">{{$t('创建')}}</van-button>
      <div class="btn cancel" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('取消')}}</div>
    </div>
    <!-- 选择人数弹出层 -->
    <van-popup v-model="numShow"
               position="bottom">
      <van-picker :title="$t('人数')"
                  :confirm-button-text="$t('确定')"
                  :cancel-button-text="$t('取消')"
                  :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
                  show-toolbar
                  :columns="columns"
                  @confirm="onConfirm"
                  @cancel="onCancel" />
    </van-popup>
  </div>
</template>

<script>
import { AddChatApi } from '@/api/api'
export default {
  data () {
    return {
      room_name: '',
      number: 2,
      numShow: false,
      columns: [2, 5, 8],
      creatLoading: false,
    }
  },
  mounted () {

  },
  methods: {
    creatRoom () {
      if (!this.room_name) {
        this.$toast(this.$t('房间名不能为空'));
        return
      }
      this.creatLoading = true
      AddChatApi({
        max_count: this.number,
        name: this.room_name
      }).then(res => {
        if (res.code == 0) {
          this.room_name = ''
          this.$emit('getChatInfo');
          this.$emit('getUserInfo');
          this.$emit('choice', 'joinRoom')
        }
        this.creatLoading = false
        this.$toast(res.msg);
      })
    },
    //打开聊天室弹窗
    // apply(){
    // 	this.$emit('openRoom');
    // },
    onConfirm (value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`);
      this.number = value
      this.numShow = false
    },
    onCancel () {
      this.numShow = false
    },
  }
}
</script>

<style lang="less" scoped>
.createRoom {
  padding: 0 33.75px;
  // div{
  //     box-sizing: border-box;
  // }
  .card {
    padding: 33.75px 15px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 7.5px;
    margin-bottom: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-family: '071-SSRuiFengTi';
      font-size: 30px;
      color: #ffffff;
      margin-bottom: 22.5px;
    }
    .van-field {
      background-color: #ffffff;
      border-radius: 18.75px;
      /deep/.van-field__right-icon {
        display: flex;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      /deep/.xia {
        width: 26.25px;
        height: 26.25px;
      }
    }
    .roomName {
      margin-bottom: 7.5px;
      /deep/.van-field__control {
        font-family: 'TsangerYuYangT-W03';
        font-size: 18.75px;
        color: #2b2b2b;
      }
      /* WebKit, Blink, Edge */
      /deep/.van-field__control::-webkit-input-placeholder {
        font-size: 18.75px;
      }
      /* Mozilla Firefox 4 to 18 */
      /deep/.van-field__control:-moz-placeholder {
        font-size: 18.75px;
      }
      /* Mozilla Firefox 19+ */
      /deep/.van-field__control::-moz-placeholder {
        font-size: 18.75px;
      }
      /* Internet Explorer 10-11 */
      /deep/.van-field__control:-ms-input-placeholder {
        font-size: 18.75px;
      }
      /* Microsoft Edge */
      /deep/.van-field__control::-ms-input-placeholder {
        font-size: 18.75px;
      }
    }
    .number {
      /deep/.van-field__control {
        font-family: 'AeroMaticsDisplayItalic';
        font-size: 18.75px;
        color: #2b2b2b;
      }
      /* WebKit, Blink, Edge */
      /deep/.van-field__control::-webkit-input-placeholder {
        font-size: 18.75px;
      }
      /* Mozilla Firefox 4 to 18 */
      /deep/.van-field__control:-moz-placeholder {
        font-size: 18.75px;
      }
      /* Mozilla Firefox 19+ */
      /deep/.van-field__control::-moz-placeholder {
        font-size: 18.75px;
      }
      /* Internet Explorer 10-11 */
      /deep/.van-field__control:-ms-input-placeholder {
        font-size: 18.75px;
      }
      /* Microsoft Edge */
      /deep/.van-field__control::-ms-input-placeholder {
        font-size: 18.75px;
      }
    }
    .btn {
      border-radius: 15px 3px 15px 3px;
      border: 2px solid rgba(0, 0, 0, 0.8);
      width: 80%;
      height: 48.75px;
      line-height: 48.75px;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 22.5px;
      color: #2b2b2b;
      text-align: center;
    }
    .creat {
      background-color: #a7f264;
      margin-bottom: 15px;
      margin-top: 63.75px;
    }
    .cancel {
      background-color: #5c65cb;
    }
  }
  .van-picker {
    /deep/.van-picker__title {
      color: #000;
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  /deep/.van-popup--bottom {
    width: 430px;
    left: calc(50% - 215px);
  }
}
</style>