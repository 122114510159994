<template>
    <div class="qrCode">
        <van-popup v-model="show" :overlay-style="{backgroundColor: 'rgba(0,0,0,0)'}" :close-on-click-overlay="false">
            <img @click="close" class="close" src="@/assets/bracelet_img/40.png" alt="">
            <div class="main">
                <div class="qrCodeTitle">聊天室008</div>
                <!-- <div class="img"></div> -->
                <img class="img" src="@/assets/bracelet_img/73.png" alt="">
                <div class="btn copyUrl pointer">复制链接</div>
                <div @click="sendInvite" class="btn sendInvite pointer">发送邀请</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
	export default {
		data() {
			return {
                show: false,
			}
		},
        props: {
            qrCodeShow: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            showMarketPopup: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
        },
        watch: {
            qrCodeShow(){
                this.show = this.qrCodeShow
            }
        },
		created(){
            
        },
		methods: {
            sendInvite(){
                this.$emit('qrCodeClose', 'openInviteFriend');
            },
			close(){
                this.$emit('qrCodeClose', 'close');
            },
		}
	}
</script>

<style lang="less" scoped>
    .qrCode{
        .van-popup{
            width: 82%;
            background-color: rgba(0, 0, 0, 0.7);
            border-radius: 7.5px;
            .close{
                width: 22.5px;
                height: 22.5px;
                position: absolute;
                right: 7.5px;
                top: 7.5px;
            }
        }
        .main{
            div{
                box-sizing: border-box;
            }
            padding: 26.25px 33.75px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .qrCodeTitle{
                font-family: 'TsangerYuYangT-W05';
	            font-size: 30px;
                margin-bottom: 15px;
            }
            .img{
                width: 236.25px;
                height: 236.25px;
                background: #fff;
                margin-bottom: 18.75px;
            }
            .btn{
                border-radius: 15px 3px 15px 3px;
                border: 2px solid rgba(0, 0, 0, 0.5);
                padding: 3px 0 7.5px 0;
                font-family: 'REEJI-PinboGB-Flash';
                font-size: 22.5px;
                color: #2b2b2b;
                width: 100%;
                text-align: center;
            }
            .copyUrl{
                background-color: #a7f264;
                margin-bottom: 15px;
            }
            .sendInvite{
                background-color: #5c65cb;
            }
        }
    }
    @media screen and (min-width: 480px){
        //在这里写非小屏幕设备的样式  -- 非手机
        .qrCode{
            .van-popup{
                width: 350px;
            }
        }
    }
</style>
