<template>
  <div class="roomHome">
    <watch-head :title="$t('社交聊天室')"
                :img="require('@/assets/bracelet_img/38.png')" />
    <div class="qiehuan">
      <div class="tab">
        <div class="item"
             :class="{'act': tabType == 'joinRoom', 'vn-bold-italic': $i18n.locale == 'vn'}"
             @click="choice('joinRoom')">{{$t('加入聊天室')}}</div>
        <div class="item"
             :class="{'act': tabType == 'createRoom', 'vn-bold-italic': $i18n.locale == 'vn'}"
             @click="choice('createRoom')">{{$t('创建聊天室')}}</div>
        <div class="item"
             :class="{'act': tabType == 'scan', 'vn-bold-italic': $i18n.locale == 'vn'}"
             @click="choice('scan')">{{$t('邀请链接')}}</div>
      </div>
    </div>

    <join-room v-show="tabType == 'joinRoom'"
               :chatInfo="chatInfo"
               :userInfo="userInfo"
               :myRoomLoading="myRoomLoading"
               @openStake="openStake"
               @getChatInfo="getChatInfo"
               @openRoom="openRoom" />
    <create-room v-show="tabType == 'createRoom'"
                 @getUserInfo="getUserInfo"
                 @choice="choice"
                 @getChatInfo="getChatInfo" />
    <scan v-show="tabType == 'scan'"
          @choice="choice"
          @getChatInfo="getChatInfo" />

    <div class="btm">
      <div class="left">
        <div @click="gotoUnityweb"
             class="box">
          <img src="@/assets/bracelet_img/5.png"
               alt="">
        </div>
      </div>
      <div class="right">
        <van-badge :dot="isRequest">
          <div @click="openRequest"
          :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
               class="ask">{{$t('请求')}}</div>
        </van-badge>
        <van-badge :dot="isInvite">
          <div @click="openInvite"
          :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
               class="invite">{{$t('邀请')}}</div>
        </van-badge>

      </div>
    </div>

    <qr-code :qrCodeShow="qrCodeShow"
             @qrCodeClose="qrCodeClose" />
    <room :roomShow="roomShow"
          :chatInfo="chatInfo"
          :userInfo="userInfo"
          @getUserInfo="getUserInfo"
          @getChatInfo="getChatInfo"
          @diggingOre="diggingOre"
          @roomClose="roomClose" />
    <invite-friends :inviteFriendShow="inviteFriendShow"
                    :chatInfo="chatInfo"
                    @inviteFriendClose="inviteFriendClose" />
    <request :requestShow="requestShow"
             :chatInfo="chatInfo"
             @getChatInfo="getChatInfo"
             @requestClose="requestClose" />
    <invite :inviteShow="inviteShow"
            @getChatInfo="getChatInfo"
            @inviteClose="inviteClose" />
    <stake :stakeShow="stakeShow"
           :chatInfo="chatInfo"
           :userInfo="userInfo"
           @diggingOre="diggingOre"
           @getChatInfo="getChatInfo"
           @stakeClose="stakeClose" />
  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import joinRoom from './components/joinRoom/index.vue'
import createRoom from './components/createRoom/index.vue'
import scan from './components/scan/index.vue'
import qrCode from './components/room/qrCode.vue'
import room from './components/room/room.vue'
import inviteFriends from './components/inviteFriends/inviteFriends.vue'
import request from './components/request/index.vue'
import invite from './components/invite/index.vue'
import stake from './components/stake/index.vue'
import { mapState } from 'vuex'
import { connectWebsocket, sendMessage, closeWebsocket } from '@/utils/websocket.js'
import { userInfo, ChatInfoApi, AddUrlRoomApi } from '@/api/api'
// import { json } from 'body-parser'
// import { ChatInfoApi } from '@/api/api'

export default {
  components: {
    joinRoom,
    createRoom,
    scan,
    qrCode,
    room,
    inviteFriends,
    request,
    stake,
    invite,
    watchHead
  },
  data () {
    return {
      tabType: 'joinRoom',
      qrCodeShow: false,
      roomShow: false,
      inviteFriendShow: false,
      requestShow: false,
      inviteShow: false,
      stakeShow: false,
      myRoomLoading: false,
      chatInfo: {},
      userInfo: {},
      isTimes: 0,
      isHours: false,
      isRequest: false,
      isInvite: false,
      WebSocketLoad: null,
      isUrlJoin: true,//判断是否能通过URL加入房间
    }
  },
  mounted () {

  },
  created () {
    this.waitForGlobal();
  },
  beforeDestroy () {
    console.log('社交页面即将进入销毁阶段-----');
    closeWebsocket()//关闭Websocket
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },

  methods: {
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
        window.clearTimeout(this.timer);
        this.getUserInfo()

      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },


    /* 获取当前用户信息 */
    getUserInfo () {
      //  this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      userInfo({}, localStorage.getItem('Token')).then(res => {
        if (res.code == 0) {
          this.userInfo = res.data
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.binding()
        }
      })

    },
    /* 初始化web socket，并绑定 */
    binding () {
      this.WebSocketLoad = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('连接中...'),
      });
      this.myRoomLoading = true
      let data = {
        type: "bind",
        uid: this.userInfo.id,
      }
      connectWebsocket(data,
        (res) => {
          console.log(res, '----------connectWebsocket-----------')
          if (res.code == 0) {
            if (res.data.code == 0) {
              this.getChatInfo()
              /* 用户直接通过链接进入页面加入聊天室 */
              if (this.$route.query.id) {
                if(this.isUrlJoin){//重连之后禁止调用接口加入房间
                  this.urlJoin(this.$route.query.id)
                }
              }
            }

            if (res.data.code) {
              this.monitor(res.data)
            }

          } else if (res.code == 1) {
            this.$toast(res.msg)
          }
          this.myRoomLoading = false
          this.WebSocketLoad.clear();
        },
        () => {
          // this.WebSocketLoad = this.$toast.loading({
          //   duration: 0, // 持续展示 toast
          //   forbidClick: true,
          //   message: '重连...',
          // });
          // let ti = setTimeout(()=>{
          //      clearTimeout(ti)
          //      this.WebSocketLoad.clear();
          // },5000)
          this.WebSocketLoad.clear();
          this.isHours = false
          console.log("失败的回调函数123")
        })
    },
    /* 用户直接通过链接进入页面加入聊天室 */
    urlJoin (id) {
      AddUrlRoomApi({ id, state: 1 }).then(res => {
        if(res.code == 0){
          this.isUrlJoin = false //进入房间后就不允许再次通过邀请链接加入
        }
        this.getChatInfo()
        this.$toast(res.msg);
      })
    },
    /* 获取当前聊天室 */
    getChatInfo () {
      ChatInfoApi().then(res => {
        //    console.log(res)
        if (res.code == 0) {

          if (!this.isHours) {
            this.sendChatId(res.data.id)
          }
          this.chatInfo = res.data
          let nums = Number(this.chatInfo.max_count) - this.chatInfo.user_arr.length
          for (let i = 0; i < nums; i++) {
            this.chatInfo.user_arr.push({});
          }
        } else {
          this.chatInfo = {}
        }
      })

    },

    // 获取房间信息再发送房间id
    sendChatId (chat_id) {
      let data = {
        chat_id,
        state: 3,
      }
      sendMessage(data)
      this.isHours = true
    },
    /* 开始 1 or 结束 2 挖矿 调用 */
    diggingOre (state) {
      if (state == 2) {
        this.stakeClose()
        this.$router.push({
          path: '/roomScore'
        })
      }

    },
    /* 监听webSocket状态码进行相应操作 */
    monitor (data) {
      // this.isHours = false
      switch (data.code) {
        case 100000:
          // xxx加入房间
          console.log('xxx加入房间---------------')
          this.getChatInfo()
          break;
        case 100001:
          // xxx退出房间
          console.log('xxx退出房间---------------')
          this.getChatInfo()
          break;
        case 100002:
          // 开始挖矿
          console.log('开始挖矿---------------')
          // this.diggingOre(1) //开始挖矿
          this.getChatInfo()
          let ti = setTimeout(() => {
            clearTimeout(ti)
            this.roomClose('startStake') // 再打开挖矿弹窗
          }, 200)

          break;
        case 100003:
          // 结束挖矿
          console.log('结束挖矿---------------')
          // this.getChatInfo()
          this.diggingOre(2) //结束挖矿
          this.$router.push({
            path: '/roomScore'
          })
          let er = setTimeout(() => {
            clearTimeout(er)
            closeWebsocket()//关闭Websocket
          }, 200)
          break;
        case 100004:
          // 有人申请加入房间
          console.log('有人申请加入房间---------------')
          // this.$toast('有新的请求，请在请求弹窗中查看')
          this.setTime(this.$t('有新的请求，请在请求弹窗中查看'))
          this.isRequest = true
          break;
        case 100005:
          // 房主同意申请 or 邀请人同意加入房间
          console.log('房主同意申请 or 邀请人同意加入房间---------------')
          this.getChatInfo()
          break;
        case 100006:
          // 收到房间邀请
          console.log('收到房间邀请---------------')
          // this.$toast('有新的邀请，请在邀请弹窗中查看');
          this.setTime(this.$t('有新的邀请，请在邀请弹窗中查看'))
          this.isInvite = true
          break;
        case 100007:
          // 房间销毁通知
          console.log('房间销毁通知---------------')
          this.getChatInfo()
          break;
        case 100008:
          // 房间状态改变
          console.log('房间状态改变---------------')
          this.getChatInfo()
          break;
        case 100009:
          // 房主拒绝申请----提示给申请的用户
          console.log('房主拒绝申请---------------')
          this.setTime(this.$t('房主已拒绝'))
          this.getChatInfo()
          break;
        case 100010:
          // 用户拒绝加入----提示给房主
          console.log('用户拒绝加入---------------')
          this.setTime(this.$t('用户拒绝加入'))
          this.getChatInfo()
          break;
        case 100011:
          // 房间销毁通知
          console.log('房间销毁通知---------------')
          this.getChatInfo()
          break;
      }
    },
    setTime (title) {
      let timer = setTimeout(() => {
        clearTimeout(timer)
        this.$toast(title);
      }, 300)
    },
    choice (type) {
     //  console.log(type)
      if (type != this.tabType) {
        this.getChatInfo()
      }
      this.tabType = type
    },
    //打开挖矿弹窗
    openStake () {
      this.stakeShow = true
    },
    //关闭挖矿弹窗
    stakeClose () {
      this.stakeShow = false
    },
    //打开邀请弹窗
    openInvite () {
      this.isInvite = false
      this.inviteShow = true
    },
    //关闭邀请弹窗
    inviteClose () {
      this.isInvite = false
      this.inviteShow = false
    },
    //打开请求弹窗
    openRequest () {
      this.isRequest = false
      this.requestShow = true
    },
    //关闭请求弹窗
    requestClose () {
      this.isRequest = false
      this.requestShow = false
    },
    //打开邀请好友弹窗
    openInviteFriend () {
      this.inviteFriendShow = true
    },
    //关闭邀请好友弹窗
    inviteFriendClose () {
      this.inviteFriendShow = false
    },
    //打开二维码弹窗
    openQrCode () {
      this.qrCodeShow = true
    },
    //关闭二维码弹窗
    qrCodeClose (value) {
      this.qrCodeShow = false
      if (value === 'openInviteFriend') {
        this.openInviteFriend();
      }
    },
    //打开聊天室弹窗
    openRoom () {
      this.roomShow = true
    },
    //关闭聊天室弹窗
    roomClose (value) {
      this.roomShow = false
      if (value === 'openQrCode') {
        this.openQrCode();
      }
      if (value === 'startStake') {
        this.openStake();
      }
      if (value === 'openInviteFriend') {
        this.openInviteFriend();
      }
    },
    gotoUnityweb () {
      window.location.href = 'https://api.metanebulas.com/unityweb/'
    },
  }
}
</script>

<style lang="less" scoped>
// div{
// 	box-sizing: border-box;
// }
.roomHome {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 67.5px;
  background: url(~@/assets/bracelet_img/8.png) no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  .qiehuan {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
    .tab {
      width: 98%;
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 26.25px 0px 0px 3.75px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7.5px;
      .item {
        width: 33%;
        text-align: center;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3.75px 18.75px 3.75px;
        font-family: '071-SSRuiFengTi';
        font-size: 18px;
        padding: 3.75px 0px;
        color: #5c65cb;
        cursor: pointer;
      }
      .act {
        background-color: #a7f264;
        color: #2b2b2b;
      }
    }
  }

  .btm {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 22.5px;
    .left {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 0 3px 3px 0;
      padding: 3px 3px 3px 0;
      margin-right: 3px;
      .box {
        padding: 3px 10px 3px 7.5px;
        background-color: rgba(43, 43, 43, 0.9);
        border-radius: 0 3px 3px 0;
        border: solid 2px #7736fe;
        border-left: none;
        display: flex;
        img {
          width: 26px;
          height: 18.75px;
        }
      }
    }
    .right {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 3px 26.25px 3px 3px;
      padding: 3.75px 20px 3.75px 7.5px;
      display: flex;
      align-items: center;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 18.75px;
      color: #2b2b2b;
      .ask {
        background-color: #5c65cb;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 0;
        margin-right: 7.5px;
        width: 150px;
        text-align: center;
      }
      .invite {
        background-image: linear-gradient(#a7f264, #a7f264),
          linear-gradient(#5c65cb, #5c65cb);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 0;
        width: 150px;
        text-align: center;
      }
    }
  }
}
</style>
