<template>
  <div class="request">
    <van-popup v-model="show"
               :overlay-style="{backgroundColor: 'rgba(0,0,0,0)'}"
               :close-on-click-overlay="false">
      <img @click="close"
           class="close"
           src="@/assets/bracelet_img/40.png"
           alt="">
      <div class="main">
        <div class="roomTitle"
             :class="{'vn-italic': $i18n.locale == 'vn'}">{{chatInfo.name || $t('您还没有创建房间')}}</div>
        <div class="subTitle"
             :class="{'vn-italic': $i18n.locale == 'vn'}"
             v-if="chatInfo.count && chatInfo.max_count">- {{chatInfo.count}} / {{chatInfo.max_count}} {{$t('准备')}} -</div>

        <div class="recommend"
             :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('请求')}} : {{count || 0}}</div>

        <van-list v-model="loading"
                  :finished="finished"
                  :finished-text="$t('没有更多了')"
                  :loading-text="$t('加载中...')"
                  :immediate-check="false"
                  :offset="20"
                  :class="{'vn-italic': $i18n.locale == 'vn'}"
                  @load="getChatList"
                  class="list">
          <div class="item"
               v-for="(item, index) in chatList"
               :key="index">
            <div class="right">
              <div class="img">
                <img :src="item.avatar"
                     alt="">
                <div class="text">{{item.level_no}}</div>
              </div>
              <div class="info">
                <div class="name">{{item.nickname}}</div>
                <div class="Ranking" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('排行')}} : {{ item.ionz_ranking == 0 ? '100+' : item.ionz_ranking }}</div>
                <div class="Ranking" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('距离')}} : {{item.distance}} {{$t('米')}}</div>
              </div>
            </div>
            <div class="left">
              <img class="mar_right"
                   @click="requestHandle(item.id, 1)"
                   src="@/assets/bracelet_img/9.png"
                   alt="">
              <img src="@/assets/bracelet_img/10.png"
                   @click="requestHandle(item.id, 2)"
                   alt="">
            </div>
          </div>
        </van-list>

        <div class="btn"
             @click="refresh" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('刷新')}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ChatListApi, ChatOperationApi } from '@/api/api'
export default {
  data () {
    return {
      show: false,
      ChatListFrom: {
        page: 1,
        limit: 10,
      },
      loading: false,
      finished: false,
      chatList: [],
      handleLoading: false,
      count: 0,
    }
  },
  props: {
    requestShow: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    chatInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  watch: {
    requestShow () {
      this.show = this.requestShow
      if (this.show) {
        this.waitForGlobal()
      }
    }
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () { },
  methods: {
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
        window.clearTimeout(this.timer);
        this.loading = true
        this.finished = false
        this.chatList = []
        this.ChatListFrom.page = 1
        this.getChatList()

      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    requestHandle (id, state) {
      if (!this.handleLoading) {
        this.handleLoading = true;

        //    console.log('同意/拒绝请求的入参-----', data);
        ChatOperationApi({ id, state }).then(res => {
          if (res.code == 0) {
            this.refresh();

            //   this.close();
          }
          this.$emit("getChatInfo");
          this.handleLoading = false;
          this.$toast(res.msg);
        })
        //    let data = {
        //        token: localStorage.getItem('Token'),
        //        method: 'ChatOperation',
        //        data: { id, state }
        //    }
        //    sendMessage(data,
        //    (res) => {
        //        console.log(res, '----------同意/拒绝请求-----------')
        //        if(res.method == "ChatOperation"){
        //            if (res.code == 0) {
        //                this.refresh();
        //                this.close();
        //                // this.sendChatId(res.data);
        //                // this.$emit("getChatInfo");
        //            }
        //            this.$emit("getChatInfo");
        //            this.handleLoading = false;
        //            this.$toast(res.msg);
        //        }
        //    },
        //    () => { console.log("失败的回调函数") })
      }
    },
    //加入成功再发送房间id
    //     sendChatId(chat_id){
    //       let data = {
    //         chat_id
    //       }
    //       sendMessage(data,
    //       (res) => {
    //         if (res.code == 0) {
    //           this.refresh();
    //           this.close();
    //         }
    //         this.$emit("getChatInfo");
    //       },
    //       () => { console.log("失败的回调函数") })
    //     },
    refresh () {
      if (!this.loading) {
        this.loading = true
        this.finished = false
        this.chatList = []
        this.ChatListFrom.page = 1
        this.getChatList()
      }
    },
    /* 获取请求列表数据 */
    getChatList () {
      ChatListApi(this.ChatListFrom).then(res => {
        //    console.log(res, '---1111111111111111111----------')
        if (res.code == 0) {
          this.count = res.count
          this.chatList = this.chatList.concat(res.data.data)
          this.loading = false;
          if (res.data.data.length < this.ChatListFrom.limit) {
            this.finished = true;
          }
          this.ChatListFrom.page++
        } else {
          this.count = 0
          this.finished = true;
        }
      })
    },
    close () {
      this.$emit('requestClose');
    },
  }
}
</script>

<style lang="less" scoped>
.request {
  .van-popup {
    width: 82%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 7.5px;
    .close {
      width: 22.5px;
      height: 22.5px;
      position: absolute;
      right: 7.5px;
      top: 7.5px;
    }
  }
  .main {
    div {
      box-sizing: border-box;
    }
    padding: 18.75px 11.25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .roomTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 30px;
    }
    .subTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 11.25px;
      margin-bottom: 11.25px;
    }

    .recommend {
      width: 100%;
      font-family: '071-SSRuiFengTi';
      font-size: 30px;
      margin-bottom: 7.5px;
    }
    .list {
      width: 100%;
      height: 43vh;
      overflow: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 100%;
        min-height: 60px;
        background-image: linear-gradient(
            237deg,
            rgba(118, 233, 214, 0.83) 0%,
            rgba(159, 174, 230, 0.83) 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 7.5px;
        padding: 7.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.75px;
        .right {
          display: flex;
          align-items: center;
          .img {
            width: 48.75px;
            height: 48.75px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: 2px solid #2b2b2b;
            position: relative;
            margin-right: 18.75px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 22.5px;
              height: 22.5px;
              line-height: 22.5px;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -13px;
              bottom: 0;
              text-align: center;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
            }
          }
          .info {
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 18.75px;
            }
            .Ranking {
              font-family: 'TsangerYuYangT-W03';
              font-size: 12px;
            }
          }
        }
        .left {
          display: flex;
          align-items: center;
          // justify-content: center;
          img {
            width: 26.25px;
            height: 26.25px;
            border-radius: 50%;
          }
          .mar_right {
            margin-right: 11.25px;
          }
        }
      }
    }
    .list::-webkit-scrollbar {
      display: none;
    }
    .btn {
      margin-top: 22.5px;
      background-color: #40e9ec;
      border-radius: 15px 3px 15px 3px;
      border: 2px solid rgba(0, 0, 0, 0.9);
      padding: 3px 75px;

      font-family: 'REEJI-PinboGB-Flash';
      font-size: 22.5px;
      color: #2b2b2b;
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .request {
    .van-popup {
      width: 350px;
    }
  }
}
</style>
