<template>
  <div class="inviteFriends">
    <van-popup v-model="show"
               :overlay-style="{backgroundColor: 'rgba(0,0,0,0)'}"
               :close-on-click-overlay="false">
      <img @click="close"
           class="close"
           src="@/assets/bracelet_img/40.png"
           alt="">
      <div class="main">
        <div class="roomTitle" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ chatInfo ? chatInfo.name : $t('暂无名称') }}</div>
        <div class="subTitle" :class="{'vn-italic': $i18n.locale == 'vn'}">- {{ chatInfo ? chatInfo.count : 0 }} / {{ chatInfo ? chatInfo.max_count : 0 }} {{$t('准备')}} -</div>

        <div class="recommend" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('邀请朋友')}}</div>
        <van-list v-model="loading"
                  :finished="finished"
                  :class="{'vn-italic': $i18n.locale == 'vn'}"
                  :finished-text="$t('没有更多了')"
                  :loading-text="$t('加载中...')"
                  :immediate-check="false"
                  :offset="20"
                  @load="onLoad"
                  class="list">
          <div class="item"
               v-for="(item, index) in list"
               :key="index">
            <div class="right">
              <div class="img">
                <van-image class="image"
                           lazy-load
                           round
                           :src="item.avatar" />
                <div class="text">{{ item.level_no }}</div>
              </div>
              <div class="info">
                <div class="name" :class="{'vn-italic': $i18n.locale == 'vn'}">{{ item.nickname ? item.nickname : $t('暂无名称') }}</div>
                <div class="Ranking" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('排行')}} : {{ item.ionz_ranking == 0 ? '100+' : item.ionz_ranking }}</div>
                <div class="Ranking" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('距离')}} : {{ item.distance }} {{$t('米')}}</div>
              </div>
            </div>
            <!-- <div v-show="item.state == 0" class="left" @click="invite(item.id, index)">
              <img src="@/assets/bracelet_img/1.png"
                   alt="">
            </div> -->
            <div class="left" @click="invite(item.id, index)">
              <img src="@/assets/bracelet_img/1.png"
                   alt="">
            </div>
          </div>
        </van-list>
        <div class="btn" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('刷新')}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getFriendsList, AddRoomRequestApi } from '@/api/api'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      show: false,

      loading: false,
      finished: false,
      page: 1,
      list: [],
      timer: null,
      addRoomLoading: false,
    }
  },
  props: {
    inviteFriendShow: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    chatInfo: {
        type: Object,
        default: function() {
            return null
        }
    }
  },
  watch: {
    inviteFriendShow () {
      this.show = this.inviteFriendShow
      if (this.show) {
        this.waitForGlobal();
      }
    }
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () {

  },
  methods: {
    invite(user_id, index){
      if(!this.chatInfo.id){
        this.$toast(this.$t('暂无房间id'));
      }
      if(!this.addRoomLoading){
        this.addRoomLoading = true
        AddRoomRequestApi({ 
          chat_id: this.chatInfo.id,
          user_id
        }).then(res => {
          if(res.code == 0){
            this.list[index].state = 1  //已发送转态改成非0
          }
          this.addRoomLoading = false
          this.$toast(res.msg);
        })
      }
    },
    onLoad () {
      getFriendsList({
        page: this.page
      }).then(res => {
        if (res.code == 0) {
          this.list = this.list.concat(res.data.data)
          this.loading = false;
          if (res.data.total <= this.page) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    close () {
      this.$emit('inviteFriendClose', 'close');
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.loading = true
        this.finished = false
        this.list = []
        this.page = 1
        this.onLoad();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.inviteFriends {
  .van-popup {
    width: 82%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 7.5px;
    .close {
      width: 22.5px;
      height: 22.5px;
      position: absolute;
      right: 7.5px;
      top: 7.5px;
    }
  }
  .main {
    div {
      box-sizing: border-box;
    }
    padding: 18.75px 11.25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .roomTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 30px;
    }
    .subTitle {
      font-family: 'TsangerYuYangT-W05';
      font-size: 11.25px;
      margin-bottom: 11.25px;
    }

    .recommend {
      width: 100%;
      font-family: '071-SSRuiFengTi';
      font-size: 30px;
      margin-bottom: 7.5px;
    }
    .list {
      width: 100%;
      height: 43vh;
      overflow: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 100%;
        min-height: 60px;
        background-image: linear-gradient(
            237deg,
            rgba(118, 233, 214, 0.83) 0%,
            rgba(159, 174, 230, 0.83) 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 7.5px;
        padding: 7.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3px;
        .right {
          width: 87%;
          display: flex;
          align-items: center;
          .img {
            width: 48.75px;
            height: 48.75px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: 2px solid #2b2b2b;
            position: relative;
            margin-right: 18.75px;
            .image {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 22.5px;
              height: 22.5px;
              line-height: 22.5px;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -13px;
              bottom: 0;
              text-align: center;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
            }
          }
          .info {
            width: calc(100% - 68px);
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 18.75px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .Ranking {
              font-family: 'TsangerYuYangT-W03';
              font-size: 12px;
            }
          }
        }
        .left {
          width: 33.75px;
          min-width: 33.75px;
          height: 33.75px;
          background-color: #6065ce;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 18.75px;
            height: 18.75px;
          }
        }
      }
    }
    .list::-webkit-scrollbar {
      display: none;
    }
    .btn {
      margin-top: 40px;
      background-color: #40e9ec;
      border-radius: 15px 3px 15px 3px;
      border: 2px solid rgba(0, 0, 0, 0.9);
      padding: 3px 75px;

      font-family: 'REEJI-PinboGB-Flash';
      font-size: 22.5px;
      color: #2b2b2b;
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .inviteFriends {
    .van-popup {
      width: 350px;
    }
  }
}
</style>
